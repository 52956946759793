export default function Arrow() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="148" viewBox="0 0 31 148" fill="none">
            <path d="M17.5 1.99829C17.5 0.893722 16.6046 -0.00170898 15.5 -0.00170898C14.3954 -0.00170898 13.5 0.893722 13.5 1.99829L17.5 1.99829ZM14.0858 147.416C14.8668 148.197 16.1332 148.197 16.9142 147.416L29.6421 134.688C30.4232 133.907 30.4232 132.641 29.6421 131.86C28.8611 131.079 27.5948 131.079 26.8137 131.86L15.5 143.173L4.18629 131.86C3.40524 131.079 2.13891 131.079 1.35786 131.86C0.576816 132.641 0.576816 133.907 1.35786 134.688L14.0858 147.416ZM13.5 1.99829L13.5 146.002H17.5L17.5 1.99829L13.5 1.99829Z" fill="url(#paint0_linear_11_77)" />
            <defs>
                <linearGradient id="paint0_linear_11_77" x1="168.5" y1="171.5" x2="149.692" y2="74.4401" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#8F82E3" />
                </linearGradient>
            </defs>
        </svg>
    );
}